<template>
  <div class="setting-page">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
      <!-- --------------------------基本信息-------------------- -->
      <el-tab-pane label="基本信息" name="1">
        <el-form
          label-width="100px"
          v-loading="dataListLoading"
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
        >
          <div v-if="isEdit">
            <el-form-item label="队列名称" prop="name">
              <el-input
                v-model="dataForm.name"
                placeholder="请输入队列名称"
                style="width: 350px"
              ></el-input>
            </el-form-item>
            <el-form-item label="队列负责人" prop="doctorId">
              <el-select
                style="width: 350px"
                v-model="dataForm.doctorId"
                clearable=""
                filterable=""
              >
                <el-option
                  v-for="(item, index) in staffList"
                  :label="item.docterName"
                  :value="item.docterId"
                  :key="item.docterId"
                  >{{ item.docterName }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="可见范围" prop="type">
              <el-select
                style="width: 350px"
                v-model="dataForm.type"
                placeholder="请选择"
              >
                <el-option label="仅自己" :value="1"></el-option>
                <el-option label="仅团队成员" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序权重" prop="weight">
              <el-input
                v-model="dataForm.weight"
                placeholder="请输入排序权重"
                style="width: 350px"
                type="number"
              ></el-input>
            </el-form-item>
            <el-form-item style="padding-top: 40px">
              <el-button type="primary" @click="submitForm">保存</el-button>
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </div>
          <div v-if="!isEdit" class="el-form-info">
            <el-form-item label="队列名称">{{ dataForm.name }}</el-form-item>
            <el-form-item label="队列负责人">{{
              dataForm.doctorName
            }}</el-form-item>
            <el-form-item label="可见范围" prop="type">
              <span v-if="dataForm.type == 1">仅自己</span>
              <span v-if="dataForm.type == 2">仅团队成员</span>
            </el-form-item>
            <el-form-item label="排序权重">{{ dataForm.weight }}</el-form-item>
            <el-form-item label="创建人">{{
              dataForm.createName
            }}</el-form-item>
            <el-form-item label="创建时间">{{
              dataForm.createTime
            }}</el-form-item>
            <el-form-item style="padding-top: 40px">
              <el-button type="primary" @click="isEdit = true">编辑</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
      <!-- --------------------------自助入组设置 /-------------------- -->
      <el-tab-pane label="自助入组设置" name="2">
        <el-form
          label-width="100px"
          v-loading="dataListLoading"
          :model="dataForm2"
          :rules="rules2"
          ref="dataForm2"
        >
          <div v-if="isEdit2">
            <el-form-item label="自助入组" prop="autoJoin">
              <el-radio v-model="dataForm2.autoJoin" :label="1">开启</el-radio>
              <el-radio v-model="dataForm2.autoJoin" :label="2">关闭</el-radio>
            </el-form-item>
            <el-form-item label="知情同意" prop="informedConsent">
              <el-radio v-model="dataForm2.informedConsent" :label="1"
                >需要签署</el-radio
              >
              <el-radio v-model="dataForm2.informedConsent" :label="2"
                >不需要签署</el-radio
              >
            </el-form-item>
            <el-form-item
              label="知情同意书"
              prop="content"
              v-if="dataForm2.informedConsent == 1"
            >
              <tiny-mce
                v-model="dataForm2.content"
                style="width: 95%"
              ></tiny-mce>
              <div class="upload-tip" style="padding-top: 16px">
                *请在需要受试者签署的地方，填入占位字符： ${signature}
              </div>
              <!-- <el-upload
                accept=".docx"
                :action="actionUrl"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :on-exceed="handleExceed"
                :limit="1"
                list-type="text"
                v-if="!fileList.length"
              >
                <el-button
                  class="upload-btn"
                  type=""
                  style="margin-left: 10px"
                  v-loading="loading"
                  ><img src="@/assets/img/upload.png" />上传附件</el-button
                >
                <div slot="tip" class="el-upload__tip" style="color: #f59a23">
                  *仅支持docx格式的文档，且在需要受试者签署的地方，填入
                  ${signature}
                </div>
              </el-upload>
              <div class="file-upload" v-if="fileList.length">
                <el-upload
                  accept=".docx"
                  :action="actionUrl"
                  :show-file-list="false"
                  :before-upload="beforeUpload"
                  :on-exceed="handleExceed"
                  :limit="1"
                  list-type="text"
                >
                  <a style="color: #4099ff; cursor: pointer">{{
                    fileList[0].name
                  }}</a>
                  <el-button
                    type="text"
                    style="margin-left: 10px"
                    v-loading="loading"
                    >重新上传</el-button
                  >
                </el-upload>
                <el-button
                  type="text"
                  style="color: #ff0000; padding-left: 24px"
                  @click="handleRemove"
                >
                  <img
                    src="@/assets/img/delete.png"
                    style="width: 15px; height: 15px"
                /></el-button>
              </div>
              <div class="upload-tip" v-if="fileList.length">
                *仅支持docx格式的文档，且在需要受试者签署的地方，填入
                ${signature}
              </div> -->
            </el-form-item>
            <el-form-item style="padding-top: 40px">
              <el-button type="primary" @click="submitForm(2)">保存</el-button>
              <el-button @click="isEdit2 = false">取消</el-button>
            </el-form-item>
          </div>
          <div v-show="!isEdit2">
            <el-form-item label="自助入组">
              <span v-if="dataForm2.autoJoin == 1">开启</span>
              <span v-if="dataForm2.autoJoin == 2">关闭</span>
              <span v-if="!dataForm2.autoJoin">-</span>
            </el-form-item>
            <el-form-item label="知情同意">
              <span v-if="dataForm2.informedConsent == 1">开启</span>
              <span v-if="dataForm2.informedConsent == 2">关闭</span>
              <span v-if="!dataForm2.informedConsent">-</span>
            </el-form-item>
            <el-form-item
              label="知情同意书"
              v-if="dataForm2.informedConsent == 1"
            >
              <el-button type="text" @click="previewHtml">查看</el-button>
              <!-- <el-button type="text" @click="downloadUrl2">{{
                fileList.length ? fileList[0].name : "-"
              }}</el-button> -->
            </el-form-item>
            <el-form-item label="入组二维码" v-if="dataForm2.autoJoin == 1">
              <div class="code-img" id="qrCodeGroup">
                <img :src="codeUrl"/>
              </div>
            </el-form-item>
            <el-form-item style="padding-top: 40px">
              <el-button type="primary" @click="isEdit2 = true">编辑</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
      <!-- --------------------------知情同意查看 /-------------------- -->
      <el-tab-pane label="知情同意查看" name="3">
        <el-form :inline="true" class="form-content">
          <el-form-item>
            <el-input
              placeholder="请输入关键字查询"
              v-model="searchValue"
              class="input-with-select"
              style="width: 450px"
            >
              <el-select
                v-model="select"
                slot="prepend"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option label="姓名" :value="1"></el-option>
                <el-option label="手机号" :value="2"></el-option>
              </el-select>
              <el-button
                slot="append"
                @click="getList"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="签署日期">
            <el-date-picker
              v-model="dateTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              style="width: 350px"
              clearable
            ></el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="getList">搜索</el-button>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="search-list">
          <el-button type="primary" @click="dowmloadUrl" v-loading="downFlag"
            >导出附件</el-button
          >
          <span class="upload-tip" style="padding-left: 24px"
            >* 单次最多导出100条知情同意书附件</span
          >
        </div>
        <el-table :data="dataList" @selection-change="selectionChangeHandle">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="姓名" prop="patienceName"></el-table-column>
          <el-table-column
            label="性别"
            prop="patienceSexName"
          ></el-table-column>
          <el-table-column
            label="手机号"
            prop="patienceLinkTel"
          ></el-table-column>
          <el-table-column
            label="知情同意签署时间"
            prop="informedConsentTime"
          ></el-table-column>
          <el-table-column label="知情同意书附件" prop="patienceName">
            <template slot-scope="scope"
              ><el-button type="text" @click="toGreetment(scope.row)"
                >查看</el-button
              ></template
            >
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="知情同意书" :visible.sync="visible" width="800px">
      <div v-html="dataForm2.content"></div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import QRCode from "qrcodejs2";
import TinyMce from "@/components/tiny-mce";
export default {
  data() {
    return {
      dataListLoading: false,
      activeName: "1",
      dataForm: {
        name: "",
        doctorId: "",
        type: "",
        weight: "0",
        teamsId: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入队列名称",
            trigger: "blur",
          },
        ],
        weight: [
          {
            required: true,
            message: "请输入排序权重",
            trigger: "blur",
          },
        ],

        type: [
          {
            required: true,
            message: "请选择可见范围",
            trigger: "blur",
          },
        ],
        doctorId: [
          {
            required: true,
            message: "请选择队列负责人",
            trigger: "blur",
          },
        ],
      },
      isEdit: false,
      staffList: [],
      dataForm2: {
        autoJoin: 2,
        informedConsent: 2,
        fileUrl: "",
      },
      rules2: {
        autoJoin: [
          {
            required: true,
          },
        ],
        informedConsent: [
          {
            required: true,
          },
        ],
        // fileUrl: [
        //   {
        //     required: true,
        //     message: "请上传知情同意书",
        //   },
        // ],
        content: {
          required: true,
          message: "请输入知情同意书内容",
        },
      },
      isEdit2: false,
      loading: false,
      actionUrl: "123",
      fileList: [],
      select: 1,
      searchValue: "",
      dataList: [],
      dateTime: [],
      dataListLoading2: false, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      flag: true,
      codeUrl: "",
      downFlag: false, //导出加载
      visible: false, //知情同意书弹窗
    };
  },
  components: {
    TinyMce,
  },
  mounted() {
    this.getTeamsMember();
  },
  methods: {
    getDataList() {
      let that = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {};

      params.stringParam2 = teamsId;
      params.intParam1 = 999;
      params.intParam2 = 1;
      params.stringParam1 = this.$route.query.studyId;
      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/cohortStudy/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status && data.data.length) {
            let res = JSON.parse(JSON.stringify(data.data[0]));
            that.dataForm = res;
            that.dataForm2 = res;
            if (res.fileUrl) {
              that.fileList.push(JSON.parse(res.fileUrl));
            }
            if (!that.dataForm2.autoJoin) {
              that.dataForm2.autoJoin = 2;
            }
            if (!that.dataForm2.informedConsent) {
              that.dataForm2.informedConsent = 2;
            }
            that.dataListLoading = false;
            if (!data.data[0].autoJoin) {
              that.isEdit2 = true;
            } else {
              that.isEdit2 = false;
            }
            // that.bindQRCode();
            that.getForeverStrTicket();
          }
        });
    },
    handleClick(value) {
      if (this.activeName == 1 || this.activeName == 2) {
        this.getDataList();
      } else {
        this.getList();
      }
    },
    getTeamsMember() {
      let _this = this;
      let params = {
        intParam2: 1,
        intParam1: 9999,
        stringParam6: 1,
        stringParam5: JSON.parse(localStorage.currentTeam).id,
      };

      _this
        .$httpAes({
          url: _this.$http.adornUrl("/teamsMember/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          _this.staffList = data.data;
          _this.getDataList();
        });
    },
    submitForm(type) {
      let _this = this;
      let params = this.dataForm;
      if (type == 2) {
        let obj = {
          id: _this.dataForm.id,
          autoJoin: _this.dataForm2.autoJoin,
          informedConsent: _this.dataForm2.informedConsent,
          // fileUrl: _this.dataForm.fileUrl,
          content: _this.dataForm2.content,
        };
        _this.$refs["dataForm2"].validate((valid) => {
          if (valid) {
            _this
              .$httpAes({
                url: _this.$http.adornUrl("/cohortStudy/add"),
                method: "post",
                data: obj,
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message({
                    message: "保存成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.isEdit2 = false;
                      _this.getDataList();
                    },
                  });
                } else {
                  _this.$message.error(data.msg);
                }
              });
          }
        });
      } else {
        _this.$refs["dataForm"].validate((valid) => {
          if (valid) {
            if (_this.flag) {
              _this.flag = false;
              _this
                .$httpAes({
                  url: _this.$http.adornUrl("/cohortStudy/add"),
                  method: "post",
                  data: params,
                })
                .then(({ data }) => {
                  if (data.status) {
                    _this.$message({
                      message: "保存成功",
                      type: "success",
                      duration: 1500,
                      onClose: () => {
                        _this.isEdit = false;
                        _this.getDataList();
                        _this.flag = true;
                      },
                    });
                  } else {
                    _this.$message.error(data.msg);
                    _this.flag = true;
                  }
                });
            }
          }
        });
      }
    },
    beforeUpload(file) {
      let _this = this;
      _this.file = file;
      _this.fileName = file.name;
      _this.startUpload(file);
    },
    // 开始上传
    startUpload(file) {
      let _this = this;
      _this.loading = true;
      let instance = axios.create({
        timeout: 200000,
      });
      let url = this.$global.baseURL + "/aliyun/oss/upload";
      const formData = new FormData();
      formData.append("file", _this.file);
      instance
        .post(url, formData)
        .then(({ data }) => {
          _this.loading = false;
          if (data.result.code == 200) {
            _this.$message({
              message: "上传成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.handleUploadSuccess(data, file);
              },
            });
          } else {
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleUploadSuccess(res, file) {
      this.fileList.push({
        name: file.name,
        url: this.$global.imgUrl + res.result.path,
      });
      this.dataForm2.fileUrl = JSON.stringify(this.fileList[0]);
    },
    /**
     * 移除图片
     */
    handleRemove(row) {
      this.fileList = [];
      // for (let i = 0; i < this.fileList.length; i++) {
      //   if (row.url == this.fileList[i].url) {
      //     if (this.fileList[i].id) {
      //       this.$http({
      //         url: this.$http.adornUrl(
      //           "/sysPic/deleteHousePic/" + this.fileList[i].id
      //         ),
      //         method: "get",
      //       }).then(({ data }) => {
      //         this.fileList.splice(i, 1);
      //         this.myFileList.splice(i, 1);
      //       });
      //     } else {
      //       this.fileList.splice(i, 1);
      //     }
      //   }
      // }
    },
    handleExceed(files, fileList) {
      this.$message({
        message: "最多只能上传" + this.maxCount + "张图片",
        type: "warning",
        duration: 1000,
      });
    },

    dowmloadUrl(row) {
      let _this = this;
      let arr = _this.dataListSelections.map((chr) => {
        return chr.informedConsentUrl;
      });
      let params = {
        key: "",
      };
      if (arr.length) {
        params.key = arr.join(",");
      }
      if (row.id) {
        params.key = row.informedConsentUrl;
      }
      this.downFlag = true;
      // axios({
      //   url: _this.$global.baseURL + '/aliyun/oss/downlownd?key=' + params.key,
      //   method: "get",
      //   headers: {
      //     "Content-Type": "application/json; charset=UTF-8",
      //     token: this.$cookie.get("token"),
      //   },
      //   responseType: "blob",
      // }).then((res) => {
      // console.log(res);
      const elink = document.createElement("a");
      elink.download = "知情同意书"; //定义表格名称，后缀是文件格式
      elink.style.display = "none";
      // const blob = new Blob([res.data], { type: "text/csv,charset=UTF-8" });
      // elink.href = URL.createObjectURL(blob);
      elink.href =
        _this.$global.baseURL + "/aliyun/oss/downlownd?key=" + params.key;
      document.body.appendChild(elink);
      elink.click();
      document.body.removeChild(elink);
      this.downFlag = false;
      // });
    },
    downloadUrl2() {
      const elink = document.createElement("a");
      elink.download = "知情同意书"; //定义表格名称，后缀是文件格式
      elink.style.display = "none";
      elink.href = this.fileList[0].url;
      document.body.appendChild(elink);
      elink.click();
    },
    bindQRCode() {
      //获取二维码

      let that = this;
      console.log(
        that.$global.dominPatient +
          "/#/queue/letterOfConsent?studyId=" +
          that.dataForm.id +
          "&autoJoin=" +
          that.dataForm.autoJoin +
          "&informedConsent=" +
          that.dataForm.informedConsen
      );
      document.getElementById("qrCodeGroup").innerHTML = "";
      that.qrcode = new QRCode("qrCodeGroup", {
        text:
          that.$global.dominPatient +
          "/#/queue/letterOfConsent?studyId=" +
          that.dataForm.id +
          "&autoJoin=" +
          that.dataForm.autoJoin +
          "&informedConsent=" +
          that.dataForm.informedConsent,
        width: 110,
        height: 110,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    getForeverStrTicket() {
      let params = {
        sceneStr: this.dataForm.id,
      };
      this.$http({
        url: this.$http.adornUrl("/weiXinQRCode/getForeverStrTicket"),
        method: "get",
        params: params,
      }).then(({ data }) => {
        this.codeUrl =
          "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.data;
      });
    },
    async getList() {
      let params = {
        stringParam1: JSON.parse(localStorage.currentTeam).id,
        intParam1: this.pageSize,
        intParam2: this.pageNo,
        stringParam2: this.dataForm.id,
      };
      if (this.select == 1) {
        params.stringParam4 = this.searchValue;
      } else {
        params.stringParam5 = this.searchValue;
      }
      if (this.dateTime.length) {
        params.stringParam6 = this.dateTime[0];
        params.stringParam7 = this.dateTime[1];
      }
      const { data: res } = await this.$httpAes({
        url: this.$http.adornUrl("/studyPatience/getStudyPatienceList"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.dataList = res.data;
        this.totalPage = res.totalCount;
      }
      this.dataListLoading = false;
    },
    reset() {
      this.select = 1;
      this.searchValue = "";
      this.dateTime = [];
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    previewHtml() {
      this.visible = true;
    },
    cancel() {
      this.isEdit2 = false;
      this.bindQRCode();
    },
    toGreetment(row) {
      // this.$router.push({
      //   name: 'agreetment',
      //   query: {studyId: this.$route.query.studyId}
      // })
      window.open(this.$global.downloadUrl + row.informedConsentUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-page {
  .file-upload {
    display: flex;
    align-items: center;
  }
  .upload-tip {
    font-size: 12px;
    line-height: 1em;
    color: #ff8800;
  }
  .upload-btn {
    display: flex;
    img {
      width: 13px;
      height: 13px;
      margin-right: 6px;
    }
  }
  .code-img {
    width: 125px;
    height: 125px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
